import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLOR } from '@/app/common/style/variables';
import {
  desktopAndBelow,
  twoColumnFlex,
  tabletAndBelow,
  tablet,
  tabletDown,
  phabletAndBelow,
  desktopDown,
  mobile,
} from '@/app/common/style/mixins';
import { rem } from '@/app/common/style/functions';
import { body2, h3, h4, separator } from '../common/style/typography';

const BlogSectionStyled = styled.div`
  padding: 64px 0 104px;

  h2 {
    ${h3}

    ${desktopDown(css`
      &.title {
        ${h4};
      }
    `)}
  }

  .blog-container .section-header h2.mini-header,
  .blog-container .latest-article p.date {
    color: ${COLOR.woodSmoke2};
  }

  .posts {
    ${twoColumnFlex()}
    flex-wrap: wrap;

    ${tablet(css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `)}
  }

  ${tabletAndBelow(css`
    padding: 40px 0 88px;
  `)}

  .post {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
    padding: 36px 0;

    &:nth-of-type(1):first-of-type:not(:last-child) {
      padding-bottom: unset;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      padding-top: unset;
    }

    &:nth-of-type(odd):not(:last-child) {
      padding-left: unset;
    }

    &:nth-of-type(odd):not(:last-child) {
      padding-left: unset;
    }

    &:nth-of-type(even):not(:last-child) {
      padding-right: unset;
    }

    &:nth-of-type(odd):last-child {
      padding-left: unset;
      padding-right: 40px;
    }

    &:nth-of-type(even):last-child {
      padding-right: unset;
      padding-left: 40px;
    }

    ${tablet(css`
      width: 250px;
      height: auto;

      &:nth-of-type(odd):not(:first-of-type):not(:last-child) {
        padding-left: unset;
        padding-right: 20px;
        padding-bottom: unset;
      }

      &:nth-of-type(even):not(:last-child) {
        padding-right: unset;
        padding-left: 20px;
      }

      &:nth-of-type(odd):last-child {
        padding-right: 20px;
      }

      &:nth-of-type(even):last-child {
        padding-left: 20px;
      }
    `)}

    .post-article {
      &.link:not(.btn):hover:not(:focus-visible),
      &.link:not(.btn) {
        border-bottom: unset;

        &::after {
          content: '';
          display: none;
        }
      }
    }

    ${tabletDown(css`
      &:hover {
        .post-article {
          .image-container {
            mix-blend-mode: multiply;
          }

          .post-details {
            mix-blend-mode: multiply;
          }

          &.link:not(.btn) {
            border-bottom: unset;

            &::after {
              content: '';
              display: none;
            }
          }
        }
      }
    `)}

    .image-container {
      margin-bottom: 24px;

      .gatsby-image-wrapper {
        width: 100%;
        max-height: 347px;
        height: 347px;
        min-width: 200px;

        ${phabletAndBelow(css`
          height: 200px;
        `)}
      }
    }

    ${tabletAndBelow(css`
      &:nth-of-type(odd):not(:first-of-type):not(:last-child) {
        padding-left: unset;
        padding-right: unset;
        padding-top: 60px;
        padding-bottom: unset;
      }

      &:nth-of-type(even):not(:last-child) {
        padding-right: unset;
        padding-left: unset;
        padding-top: 60px;
        padding-bottom: unset;
      }

      &:nth-of-type(odd):last-child {
        padding-right: unset;
        padding-top: 60px;
        padding-bottom: unset;
      }

      &:nth-of-type(even):last-child {
        padding-left: unset;
        padding-top: 60px;
        padding-bottom: unset;
      }

      .image-container {
        .gatsby-image-wrapper {
          width: fit-content;
          padding: unset;
        }
      }
    `)}

    .post-details {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3.title {
        margin-bottom: 11.5px;
        color: ${COLOR.woodSmoke1};
        letter-spacing: 0.5px;

        ${desktopDown(css`
          margin-bottom: 16px;
        `)}
      }

      .meta-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .category,
        .date {
          color: ${COLOR.woodSmoke3};
          font-size: ${rem(16)};
          line-height: 26px;
          margin-bottom: 8px;
        }

        .category {
          text-transform: uppercase;
        }

        span.separator {
          ${separator};
        }

        .date {
          padding-right: 3px;

          &::after {
            content: '';
            display: inline-flex;
            background-image: url(/assets/images/arrow-right.svg);
            background-repeat: no-repeat;
            -webkit-background-position: right;
            background-position: right;
            width: 17px;
            height: 14px;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto 0 0 12px;
            position: relative;
          }
        }

        ${desktopDown(css`
          .category,
          .date {
            ${body2};
          }
        `)}

        .link {
          width: fit-content;
          width: -moz-fit-content;
          color: ${COLOR.black};

          &:hover {
            border-bottom: unset;
          }
        }
      }
    }

    > div {
      &:nth-of-type(2) {
        ${mobile(css`
          padding-left: 0;
          padding: 8px 0 0;
        `)}
      }
    }

    ${desktopAndBelow(css`
      flex-direction: column;
      > div {
        &:not(:last-child) {
          padding: 0 0 8px 0;
        }

        &:not(:first-of-type) {
          padding: 8px 0 0;
        }
      }
    `)}
  }

  ${tabletAndBelow(css`
    padding-bottom: 88px;

    .posts {
      padding-top: unset;

      .post {
        padding: 36px 0;

        &:first-of-type {
          padding-top: unset;
        }

        .post-details {
          min-width: 320px;
          max-width: fit-content;
          width: 100%;
        }
      }
    }
  `)}
`;

export default BlogSectionStyled;
